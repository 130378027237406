import React from "react";
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';
import TextBody from '@lmig/dotcom-aspect-components/Utility/TextBody';
import Hr from '@lmig/dotcom-aspect-components/Utility/Hr';
import LinkEnvironmentalize from "@lmig/dotcom-aspect-components/LinkEnvironmentalize";
import { Link } from "@lmig/lmds-react";
import withPreprocessedContent from "@lmig/dotcom-aspect-comparion-components/Utility/withPreprocessedContent";
import Head from "@lmig/dotcom-aspect-comparion-components/Head";
import Layout from "@lmig/dotcom-aspect-comparion-components/Layout";
import CardsWithLinks from "@lmig/dotcom-aspect-comparion-components/CardsWithLinks";
import WrapperSizeable from "@lmig/dotcom-aspect-comparion-components/Utility/WrapperSizeable";

const cardsContent = [
  {
    heading: "Find an agent",
    buttonText: "Insurance agents",
    buttonLink: "/insurance-agent",
  },
  {
    heading: "Browse insurance",
    buttonText: "Products",
    buttonLink: "/insurance",
  }
];

const NotFound = withPreprocessedContent(() => (
  <>
    <Head title='Not Found | Comparion Insurance' description='404 Not Found' />
    <Layout>
      <WrapperSizeable margin={0} padding="2rem">
        <Heading align="center" tag="h1" type="h2-light">404? Well, that's not what we were hoping for.</Heading>
        <TextBody align="center">
          <p>We can't find the page you're looking for.</p>
        </TextBody>
      </WrapperSizeable>
      <Hr type="light" marginBottom="0rem" />
      <WrapperSizeable margin={0} padding="2rem" backgroundColor="lightLibertyTeal">
        <Heading align="center" tag="h2" type="h3-light">Here's what you can do in the meantime:</Heading>
        <CardsWithLinks cards={cardsContent} />
        <div align="center">
          <LinkEnvironmentalize domain="COMPARION"><Link variant="standalone" href="">Or, visit our homepage</Link></LinkEnvironmentalize>
        </div>
      </WrapperSizeable>
    </Layout>
  </>
));

export default NotFound;