import React from "react";
import PropTypes from "prop-types";
import CardBase from '@lmig/dotcom-aspect-components/Utility/CardBase';
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';
import { Button } from '@lmig/lmds-react';

const CardWithLink = ({ heading, buttonText, buttonLink }) => (
  <CardBase shadow padding >
    <div className="cardContent">
      <Heading align="center" type="h4-bold" tag="h3" marginTop="none">{heading}</Heading>
      <div align="center" data-theme="evolved">
        <Button href={buttonLink}>{buttonText}</Button>
      </div>
    </div>
    <style jsx>{`
      @import './global-styles/tokens';
      .cardContent {
        padding: none;
        @media screen and (min-width: $lm-breakpoint-md-min) {
          padding: 1rem;
        }
      }  
    `}</style>
  </CardBase>
);


CardWithLink.propTypes = {
  heading: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};

const CardsWithLinks = ({ cards }) => (
  <div className="rowCards">
    {cards.map(({ heading, ...props }) => (
      <CardWithLink key={heading} heading={heading} {...props} />
    ))}
    <style jsx>{`
      @import './global-styles/tokens';
      .rowCards {
        display: grid;
        flex-direction: column;
        gap: 2rem;
        justify-content: center;
        margin-bottom: 2rem;
        margin-top: 3rem;
        @media screen and (min-width: $lm-breakpoint-md-min) {
          flex-direction: row;
          display: flex;
        }
      }  
    `}</style>
  </div>
);

export { CardWithLink };
export default CardsWithLinks;